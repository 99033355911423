import React from 'react';
import {RulebookSection as RulebookSectionType} from "../../types/RulebookSection";
import {Fieldset} from "primereact/fieldset";

interface RulebookSectionProps {
    className?: string;
    rulebookSection: RulebookSectionType;
    level: number;
}

const RulebookSection = ({className = "", rulebookSection, level}: RulebookSectionProps) => {

    const titleStyle = level === 1 ? "text-xl font-bold text-white mb-6" : "text-md font-bold text-white mb-4";

    return (
        <section className={`${className}`} key={rulebookSection.id}>
            <h2 className={`${titleStyle}`}>{rulebookSection.content}</h2>

            {rulebookSection.rulebookSections && rulebookSection.rulebookSections.length > 0 && (
                <div className="ml-6 border-l border-gray-700 pl-4">
                    {rulebookSection.rulebookSections.map((rulebookSection) => (
                        <RulebookSection
                            key={rulebookSection.id}
                            rulebookSection={rulebookSection}
                            level={level + 1}
                        />
                    ))}
                </div>
            )}
        </section>
    );
};

export default RulebookSection;