import React from 'react';
import Header from "../../components/layout/Header";

const Home = () => {
    return (
        <>
            <Header/>
        </>
    );
};

export default Home;