import React, {useEffect, useState} from 'react';
import {ProgressBar} from "primereact/progressbar";
import apiRequest from "../../../../helpers/apiHelper";
import RulebookSection from "./RulebookSection";
import {RulebookSection as RulebookSectionType} from "../../../../types/RulebookSection";
import EditableSection from "./EditableSection";
import Button from "../../../../components/ui/Button";
import {useToast} from "../../../../components/provider/ToastProvider";

const RulebookCrud = () => {
    const {showToast} = useToast();
    const [rulebookSections, setRulebookSections] = useState<RulebookSectionType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [addingNewSection, setAddingNewSection] = useState<boolean>(false);
    const [newSectionContent, setNewSectionContent] = useState<string>("");

    const fetchRulebook = async () => {
        setLoading(true);
        apiRequest("get", "/api/rulebook")
            .then((data) => {
                setRulebookSections(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching rulebook:", error);
                setLoading(false);
            });
    };

    const handleAddSection = (parentId: number | null, newSection: Partial<RulebookSectionType>) => {
        setLoading(true);
        apiRequest("post", "/api/rulebook", {
            parent_id: parentId, // Pass `null` for top-level sections
            content: newSection.content ?? "",
        })
            .then(() => {
                fetchRulebook();
                if (parentId === null) setAddingNewSection(false); // Reset form for top-level sections
                showToast("Rulebook section added", "success");
            })
            .catch(error => {
                console.error("Error adding rulebook section:", error);
                setLoading(false);
            });
    };

    const handleUpdate = (id: number, updatedSection: Partial<RulebookSectionType>) => {
        setLoading(true);
        apiRequest("put", `/api/rulebook/${id}`, updatedSection)
            .then(() => {
                fetchRulebook();
                showToast("Rulebook section updated", "success");
            })
            .catch(error => {
                console.error("Error updating rulebook section:", error);
                setLoading(false);
            });
    };

    const handleDelete = (id: number) => {
        setLoading(true);
        apiRequest("delete", `/api/rulebook/${id}`)
            .then(() => {
                fetchRulebook();
                showToast("Rulebook section deleted", "success");
            })
            .catch(error => {
                console.error("Error deleting rulebook section:", error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchRulebook();
    }, []);

    return (
        <main>
            <h1 className="text-3xl font-bold text-white mb-6">Rulebook</h1>
            {!loading ? (
                <>
                    <section className="bg-gray-800 p-4 rounded-lg">
                        {rulebookSections.map((rulebookSection) => (
                            <RulebookSection
                                className="mb-5"
                                key={rulebookSection.id}
                                rulebookSection={rulebookSection}
                                onAdd={(parentId, newSection) => handleAddSection(parentId, newSection)}
                                onUpdate={handleUpdate}
                                onDelete={handleDelete}
                                level={1}
                            />
                        ))}
                    </section>
                    <section className="bg-gray-800 p-4 mb-4 rounded-lg">
                        {!addingNewSection ? (
                            <Button size="medium" onClick={() => setAddingNewSection(true)}>Add new section</Button>
                        ) : (
                            <EditableSection
                                content={newSectionContent}
                                onContentChange={setNewSectionContent}
                                onSave={() => handleAddSection(null, {content: newSectionContent})}
                                onCancel={() => setAddingNewSection(false)}
                                saveLabel="Add"
                                cancelLabel="Cancel"
                            />
                        )}
                    </section>
                </>
            ) : (
                <div className="w-full">
                    <p className="text-gray-300 mb-3">Loading rulebook...</p>
                    <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                </div>
            )}
        </main>
    );
};

export default RulebookCrud;
