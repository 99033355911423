import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { IoLogOutOutline } from 'react-icons/io5';
import { FaUser, FaGear, FaArrowLeft, FaBars } from 'react-icons/fa6';
import { Button } from 'primereact/button';

interface AdminHeaderProps {
    onToggleSidebar: () => void;
}

const AdminHeader = ({ onToggleSidebar }: AdminHeaderProps) => {
    const navigate = useNavigate();
    const profileMenu = useRef<Menu>(null);

    const items: MenuItem[] = [
        {
            label: 'Profile',
            icon: <FaUser className="mr-2"/>,
            disabled: true,
            command: () => {
                navigate('/profile');
            }
        },
        {
            label: 'Settings',
            icon: <FaGear className="mr-2"/>,
            command: () => {
                navigate('/settings');
            }
        },
        {
            label: 'Logout',
            icon: <IoLogOutOutline className="mr-2"/>,
            command: () => {
                navigate('/logout');
            }
        }
    ];

    return (
        <div className="w-full bg-slate-800 py-5 shadow-lg">
            <header className="flex items-center justify-between mx-6">
                <div className="flex items-center gap-x-4">
                    <Button icon={<FaBars />} className="p-button-text text-white md:hidden" onClick={onToggleSidebar} />
                    <Button
                        icon={<FaArrowLeft className="mr-2" />}
                        label="Back to Website"
                        onClick={() => navigate('/')}
                        className="p-button-outlined p-button-secondary hidden md:inline-flex"
                    />
                </div>
                <nav className="flex gap-x-2">
                    <button onClick={(event) => profileMenu.current?.toggle(event)} className="p-button p-button-rounded p-button-secondary">
                        <FaUser className="text-white" />
                    </button>
                    <Menu model={items} popup ref={profileMenu} id="admin_profile_menu" />
                </nav>
            </header>
        </div>
    );
};

export default AdminHeader;
