import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';

const AdminLayout = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible((prev) => !prev);
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <AdminSidebar isVisible={isSidebarVisible} onHide={() => setSidebarVisible(false)} />
            <div className="flex flex-col w-full">
                <AdminHeader onToggleSidebar={toggleSidebar} />
                <div className="p-6 bg-slate-900 flex-1 overflow-y-auto">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
