import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from "./auth/ProtectedRoute";
import Home from "./pages/Home";
import Logout from "./pages/Auth/Logout";
import Login from "./pages/Auth/Login";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AuthProvider, {useAuth} from "./auth/AuthContext";
import Zones from "./pages/Zones";
import Areas from "./pages/Areas";
import AreaDetail from "./pages/Areas/detail";
import Admin from "./pages/Admin/Admin";
import AdminLayout from "./pages/Admin/AdminLayout";
import RulebookCrud from "./pages/Admin/pages/Rulebook/RulebookCrud";
import {ToastProvider} from "./components/provider/ToastProvider";
import NotFound from "./pages/NotFound";
import Rulebook from "./pages/Rulebook";

const AppRoutes = () => {
    const {token} = useAuth();

    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, // wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <Home/>
                },
                {
                    path: '/rulebook',
                    element: <Rulebook/>
                },
                {
                    path: "/zones",
                    element: <Zones/>
                },
                {
                    path: "/areas",
                    element: <Areas/>
                },
                {
                    path: "/areas/:areaId",
                    element: <AreaDetail/>
                },
                {
                    path: '/settings',
                    element: <Home/>
                },
                {
                    path: '/admin',
                    element: <AdminLayout/>,
                    children: [
                        {path: '', element: <Admin/>},
                        {path: 'rulebook', element: <RulebookCrud/>},
                        {path: 'zones', element: <Admin/>},
                        {path: 'areas', element: <Admin/>},
                        {path: 'elections', element: <Admin/>},
                        {path: 'users', element: <Admin/>}
                    ]
                },
                {
                    path: "/logout",
                    element: <Logout/>
                },
                {
                    path: "*", // Fallback route, 404 page
                    element: <NotFound />
                }
            ]
        }
    ];

    const routesForNotAuthenticatedOnly = [
        {
            path: "/login",
            element: <Login/>
        }
    ];

    const router = createBrowserRouter([
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly
    ]);

    return <RouterProvider router={router}/>;
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <ToastProvider>
                <AppRoutes/>
            </ToastProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
