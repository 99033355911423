import React from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { useNavigate } from 'react-router-dom';
import { FaBook, FaMapMarkedAlt, FaMap, FaUserFriends, FaUser } from 'react-icons/fa';

const AdminSidebar = ({ isVisible, onHide }: { isVisible: boolean, onHide: () => void }) => {
    const navigate = useNavigate();

    const items = [
        {
            label: 'Rulebook',
            icon: <FaBook className="mr-2"/>,
            command: () => { navigate('/admin/rulebook'); onHide(); }
        },
        {
            label: 'Zones',
            icon: <FaMapMarkedAlt className="mr-2"/>,
            command: () => { navigate('/admin/zones'); onHide(); }
        },
        {
            label: 'Areas',
            icon: <FaMap className="mr-2"/>,
            command: () => { navigate('/admin/areas'); onHide(); }
        },
        {
            label: 'Elections',
            icon: <FaUserFriends className="mr-2"/>,
            command: () => { navigate('/admin/elections'); onHide(); }
        },
        {
            label: 'Users',
            icon: <FaUser className="mr-2"/>,
            command: () => { navigate('/admin/users'); onHide(); }
        }
    ];

    return (
        <div className={`fixed inset-0 z-40 bg-slate-800 p-4 w-64 shadow-lg transition-transform duration-300 transform ${isVisible ? 'translate-x-0' : '-translate-x-full'} md:static md:translate-x-0`}>
            <div className="flex items-center justify-center mb-6">
                <button onClick={() => navigate('/admin')}>
                    <h2 className="text-white text-2xl font-bold">Admin</h2>
                </button>
            </div>
            <PanelMenu model={items} className="text-white mt-8" />
            <button onClick={onHide} className="p-button p-button-text text-white mt-4 md:hidden">
                Close
            </button>
        </div>
    );
};

export default AdminSidebar;
