import React from 'react';

const Admin = () => {
    return (
        <div>
            <p className="text-gray-300 mb-3">This page is coming soon!</p>
            
        </div>
    );
};

export default Admin;