import React, {useState} from "react";
import {RulebookSection as RulebookSectionType} from "../../../../types/RulebookSection";
import Button from "../../../../components/ui/Button";
import {FaPlus, FaRegEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {Tag} from "primereact/tag";
import EditableSection from "./EditableSection";

interface RulebookSectionProps {
    className?: string;
    rulebookSection: RulebookSectionType;
    onDelete: (id: number) => void;
    onUpdate: (id: number, updatedSection: Partial<RulebookSectionType>) => void;
    onAdd: (parentId: number, newSection: Partial<RulebookSectionType>) => void;
    level: number;
}

const RulebookSection = ({className = "", rulebookSection, onDelete, onUpdate, onAdd, level}: RulebookSectionProps) => {
    const [editable, setEditable] = useState<boolean>(false);
    const [content, setContent] = useState<string>(rulebookSection.content ?? "");

    const [adding, setAdding] = useState<boolean>(false);
    const [newContent, setNewContent] = useState<string>("");

    const handleSave = () => {
        onUpdate(rulebookSection.id!, {content});
        setEditable(false);
    };

    const handleAdd = () => {
        if (newContent.trim()) {
            onAdd(rulebookSection.id!, {content: newContent, rulebookSections: []});
            setNewContent("");
            setAdding(false);
        }
    };

    return (
        <section key={rulebookSection.id} className={`flex flex-col rounded-lg mb-1 ${className}`}>
            {!editable ? (
                <div className="flex flex-col mb-2 hover:bg-gray-700">
                    <div className="flex items-center justify-between">
                        <div className="flex hover:cursor-pointer" onClick={() => setEditable(true)}>
                            <p className="text-gray-300">{rulebookSection.content}</p>
                        </div>
                        <div className="flex space-x-2">
                            <Button size="small" onClick={() => setEditable(true)}><FaRegEdit/></Button>
                            <Button size="small" onClick={() => onDelete(rulebookSection.id!)}><MdDelete/></Button>
                        </div>
                    </div>
                </div>
            ) : (
                <EditableSection
                    content={content}
                    onContentChange={setContent}
                    onSave={handleSave}
                    onCancel={() => setEditable(false)}
                    saveLabel="Save"
                />
            )}
            {rulebookSection.rulebookSections &&
                rulebookSection.rulebookSections.length > 0 && (
                    <div className="ml-6 border-l border-gray-700 pl-4">
                        {rulebookSection.rulebookSections.map((child) => (
                            <RulebookSection
                                key={child.id}
                                rulebookSection={child}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                                onAdd={onAdd}
                                level={level + 1}
                            />
                        ))}
                    </div>
                )}

            {adding && (
                <EditableSection
                    content={newContent}
                    onContentChange={setNewContent}
                    onSave={handleAdd}
                    onCancel={() => setAdding(false)}
                    saveLabel="Add"
                />
            )}

            {level <= 2 && (
                <Button className="self-start" size="small" onClick={() => setAdding(true)}><FaPlus/></Button>
            )}
        </section>
    );
};

export default RulebookSection;
