import React, {useEffect, useState} from 'react';
import Header from "../../components/layout/Header";
import BreadCrumb from "../../components/layout/BreadCrumb";
import apiRequest from "../../helpers/apiHelper";
import {RulebookSection as RulebookSectionType} from "../../types/RulebookSection";
import {ProgressBar} from "primereact/progressbar";
import RulebookSection from "./RulebookSection";
import {Fieldset} from "primereact/fieldset";

const Rulebook = () => {

    const [rulebook, setRulebook] = useState<RulebookSectionType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchRulebook = () => {
        apiRequest("get", "/api/rulebook")
            .then((data) => {
                setRulebook(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching rulebook:", error);
            });
    }

    useEffect(() => {
        fetchRulebook();
    }, []);

    return (
        <>
            <Header page="rulebook"/>
            <main className="container mb-7">
                <BreadCrumb items={[{label: 'Rulebook'}]}/>
                <h1 className="text-3xl font-bold text-white mb-6">Rulebook</h1>
                {!loading ? (
                    rulebook.map((rulebookSection) => (
                        <Fieldset key={rulebookSection.id} legend={rulebookSection.content} toggleable collapsed>
                            {rulebookSection.rulebookSections && rulebookSection.rulebookSections.map((rulebookSection) => (
                                <RulebookSection key={rulebookSection.id} rulebookSection={rulebookSection} level={2}/>
                            ))}
                        </Fieldset>
                    ))
                ) : (
                    <div className="w-full">
                        <p className="text-gray-300 mb-3">Loading rulebook</p>
                        <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                    </div>
                )}
            </main>
        </>
    );
};

export default Rulebook;
