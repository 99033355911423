import React from 'react';
import {BreadCrumb} from 'primereact/breadcrumb';
import {useNavigate} from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";

interface BreadcrumbItem {
    label: string;
    url?: string;
}

interface BreadcrumbsProps {
    items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({items}) => {
    const navigate = useNavigate();

    const breadcrumbItems = items.map((item) => ({
        label: item.label,
        command: () => item.url && navigate(item.url),
    }));

    return (
        <BreadCrumb
            model={breadcrumbItems}
            home={{icon: <IoHomeOutline />, command: () => navigate('/')}}
            className="mb-7 shadow-lg"
        />
    );
};

export default Breadcrumbs;
