import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Header from "../../components/layout/Header";
import apiRequest from "../../helpers/apiHelper";
import {ProgressBar} from "primereact/progressbar";
import {Area} from "../../types/Area";
import BreadCrumb from "../../components/layout/BreadCrumb";

const AreaDetail = () => {
    
    const breadcrumbItems = (area: Area | null) => [
        {label: 'Areas', url: '/areas'},
        {label: area ? area.name : '' }
    ];

    const {areaId} = useParams<{ areaId: string }>();
    const [area, setArea] = useState<Area | null>(null);

    useEffect(() => {
        if (areaId) {
            apiRequest("get", `/api/areas/${areaId}`)
                .then(data => {
                    setArea(data);
                })
                .catch(error => {
                    console.error("Error fetching area detail:", error);
                });
        }
    }, [areaId]);

    return (
        <>
            <Header page="areas"/>
            <main className="container mb-7">
                <BreadCrumb items={breadcrumbItems(area)}/>

                {area !== null ? (
                    <>
                        <section>
                            <div className="flex justify-between">
                                <div>
                                    <h1 className="text-3xl font-bold text-white mb-6">{area?.name}</h1>
                                    {area?.content || <i>No content available.</i>}
                                </div>
                                <div className="flex flex-col rounded p-5 lg:min-w-72 bg-gray-700 shadow-xl">
                                    <div className="flex justify-between items-center mb-7">
                                        <h3 className="text-3xl font-bold">{area.name}</h3>
                                        <div className="aspect-square border-2 border-gray-400 rounded-full flex items-center justify-center w-12 h-12">
                                        <span className="text-gray-300 text-xl">    
                                            {area.number}
                                        </span>
                                        </div>
                                    </div>
                                    <h3 className="text-2xl mb-2">{area.abbreviation}</h3>
                                    <div className="text-gray-300 mb-4">
                                        <p>
                                            <span className="inline-block text-sm text-gray-400 min-w-[60px]">Owner:</span>
                                            {area?.owner?.gamerTag}
                                        </p>
                                        <p>
                                            <span className="inline-block text-sm text-gray-400 min-w-[60px]">Zone:</span>
                                            {area.zone.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ) : (
                    <div className="w-full">
                        <p className="text-gray-300 mb-3">Loading area details...</p>
                        <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                    </div>
                )}

            </main>
        </>
    );
};

export default AreaDetail;
