import React, {createContext, useContext, useRef} from 'react';
import {Toast} from 'primereact/toast';

const ToastContext = createContext<{ showToast: (message: string, severity?: "success" | "info" | "warn" | "error" | "secondary" | "contrast" | undefined) => void }>({
    showToast: () => {
    },
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const toastRef = useRef<Toast>(null);

    const showToast = (message: string, severity: "success" | "info" | "warn" | "error" | "secondary" | "contrast" | undefined = "success") => {
        toastRef.current?.show({severity, summary: message, life: 3000});
    };

    return (
        <ToastContext.Provider value={{showToast}}>
            <Toast ref={toastRef}/>
            {children}
        </ToastContext.Provider>
    );
};
