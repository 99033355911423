import React from "react";
import Textarea from "../../../../components/form/Textarea";
import Button from "../../../../components/ui/Button";

interface EditableSectionProps {
    content: string;
    onContentChange: (content: string) => void;
    onSave: () => void;
    onCancel: () => void;
    saveLabel: string;
    cancelLabel?: string;
}

const EditableSection = ({content, onContentChange, onSave, onCancel, saveLabel, cancelLabel = 'Cancel'}: EditableSectionProps) => {
    return (
        <div className="space-y-4">
            <Textarea
                value={content}
                onChange={(e) => onContentChange(e.target.value)}
                rows={5}
                className="w-full"
            />
            <div className="flex items-center justify-between pb-2">
                <div className="flex space-x-2">
                    <Button size="small" onClick={onSave}>
                        {saveLabel}
                    </Button>
                    <Button size="small" onClick={onCancel}>
                        {cancelLabel}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditableSection;