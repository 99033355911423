import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from "../components/ui/Button";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-slate-900 text-white">
            <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
            <p className="text-lg mb-6">The page you are looking for does not exist.</p>
            <Button onClick={() => navigate("/")}>Go back to home</Button>
        </div>
    );
};

export default NotFound;