import React, {useEffect, useRef, useState} from 'react';
import Header from "../../components/layout/Header";
import apiRequest from "../../helpers/apiHelper";
import {Zone} from "../../types/Zone";
import {OverlayPanel} from 'primereact/overlaypanel';
import {Coordinate} from "../../types/Coordinate";
import {ProgressBar} from "primereact/progressbar";
import BreadCrumb from "../../components/layout/BreadCrumb";

const Zones = () => {
    const [zones, setZones] = useState<Zone[]>([]);
    const overlayRefs = useRef<(OverlayPanel | null)[]>([]); // Array of refs for each zone overlay

    const fetchZones = () => {
        apiRequest("get", "/api/zones")
            .then((data) => {
                setZones(moveZone(data));
            })
            .catch(error => {
                console.error("Error fetching zones:", error);
            });
    };

    const moveZone = (zones: Zone[]) => {
        const firstElement = zones.shift();
        if (!firstElement) {
            return zones;
        }
        zones.splice(84, 0, firstElement);
        return zones;
    };

    const typeClasses = (zone: Zone) => {
        switch (zone.type) {
            case "directed zone":
                return "bg-gray-700";
            case "no-build zone":
                return "no-build-zone-pattern";
            case "free zone":
                return "bg-green-500";
            default:
                return "bg-gray-500";
        }
    };

    const borderTypeClasses = (zone: Zone) => {
        if (zone.number === 1) {
            return "border-l-yellow-500 border-l-4"
        }
        
        switch (zone.type) {
            case "directed zone":
                return "border-l-gray-700 border-l-4";
            case "no-build zone":
                return "border-l-red-500 border-l-4";
            case "free zone":
                return "border-l-green-500 border-l-4";
            default:
                return "border-l-gray-500 border-l-4";
        }
    }

    const showOverlay = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
        if (overlayRefs.current[index]) {
            overlayRefs.current[index]!.show(event, event.currentTarget);
        }
    };

    const hideOverlay = (index: number) => {
        if (overlayRefs.current[index]) {
            overlayRefs.current[index]!.hide();
        }
    };

    useEffect(() => {
        fetchZones();
    }, []);

    return (
        <>
            <Header page="zones"/>
            <main className="container mb-7">
                <BreadCrumb items={[{label: 'Zones'}]}/>
                <h1 className="text-3xl font-bold text-white mb-6">Zones</h1>
                <p className="text-white mb-4">Hover over a zone to see more information.</p>
                <section className="flex flex-col-reverse lg:flex-row lg:gap-x-10">
                    <div className="flex flex-wrap justify-between gap-x-1 gap-y-2 w-full lg:w-5/6">
                        {zones.length > 0 ? zones.map((zone, index) => (
                            <div
                                key={zone.id}
                                className={`flex justify-center items-center p-1 rounded-md w-[7%] shadow-md aspect-square hover:border-white hover:border-[1px] hover:cursor-help box-border ${typeClasses(zone)}`}
                                onMouseEnter={(e) => showOverlay(e, index)}
                                onMouseLeave={() => hideOverlay(index)}
                            >
                                <h2 className="text-2xl font-bold text-white">{zone.number}</h2>

                                <OverlayPanel className={`rounded-lg shadow-2xl min-w-64 ${borderTypeClasses(zone)}`} closeOnEscape ref={(el) => (overlayRefs.current[index] = el)}>
                                    <div className="mb-5">
                                        <h3 className="text-xl font-semibold mb-5">{zone.name}</h3>
                                        <p><span className="text-sm text-gray-400">Type:</span> {zone.type}</p>
                                        <p><span className="text-sm text-gray-400">Director:</span> {zone.director ? zone.director.gamerTag : <i>unknown</i>}</p>
                                    </div>
                                    <p><span className="text-sm text-gray-400">Coordinates:<br/></span></p>
                                    {zone.coordinates.map((coordinate: Coordinate) => (
                                        <div className="flex">
                                            <span className="w-1/2">x: {coordinate.x}</span> <span className="w-1/2">z: {coordinate.z}</span>
                                        </div>
                                    ))}
                                </OverlayPanel>
                            </div>
                        )) : (
                            <div className="w-full">
                                <p className="text-gray-300 mb-3">Loading zones</p>
                                <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                            </div>
                        )}
                    </div>
                    <div className="flex w-full lg:w-1/6">
                        <div className="flex flex-col gap-2">
                            <h2 className="text-2xl font-bold text-white mb-4">Legend</h2>
                            <div className="flex items-center">
                                <div className="w-4 h-4 bg-gray-700 mr-2"></div>
                                <span className="text-white">Directed Zone</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-4 h-4 no-build-zone-pattern mr-2"></div>
                                <span className="text-white">No-Build Zone</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-4 h-4 bg-green-500 mr-2"></div>
                                <span className="text-white">Free Zone</span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Zones;
