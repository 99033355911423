import React from 'react';
import {useNavigate} from "react-router-dom";

interface HeaderNavItemProps {
    children?: React.ReactNode;
    path: string;
    active?: boolean;
}

const HeaderNavItem = ({children, path, active}: HeaderNavItemProps) => {
    const navigate = useNavigate();
    
    const activeClass = active ? 'text-white underline' : 'text-gray-300';
    
    return (
        <button className={`hover:underline ${activeClass}`} onClick={() => navigate(path)}>{children}</button>
    );
};

export default HeaderNavItem;