import React, {useEffect, useState} from 'react';
import Header from "../../components/layout/Header";
import apiRequest from "../../helpers/apiHelper";
import {ProgressBar} from "primereact/progressbar";
import {Area} from "../../types/Area";
import {useNavigate} from "react-router-dom";
import BreadCrumb from "../../components/layout/BreadCrumb";

const Areas = () => {
    const [areas, setAreas] = useState<Area[]>([]);
    const navigate = useNavigate();

    const fetchAreas = () => {
        apiRequest("get", "/api/areas")
            .then((data) => {
                setAreas(data);
                console.log(data)
            })
            .catch(error => {
                console.error("Error fetching areas:", error);
            });
    };

    useEffect(() => {
        fetchAreas();
    }, []);

    return (
        <>
            <Header page="areas"/>
            <main className="container mb-7">
                <BreadCrumb items={[{label: 'Areas'}]}/>
                <h1 className="text-3xl font-bold text-white mb-6">Areas</h1>
                <p className="text-white mb-4">Click on an area to see more information.</p>
                {areas.length > 0 ? (
                    <section className="grid md:grid-cols-2 gap-y-5 gap-x-4">
                        {areas.map((area, index) => (
                            <div className="group flex items-center justify-between bg-gray-700 py-5 px-10 rounded shadow-md border-2 border-transparent hover:border-gray-400 hover:cursor-pointer transition-colors duration-200 box-content"
                                 onClick={() => navigate(`/areas/${area.id}`)}>
                                <div>
                                    <h3 className="text-2xl font-bold">{area.name}</h3>
                                    <h4 className="text-md text-gray-100">{area.owner?.gamerTag}</h4>
                                    <h4 className="text-sm text-gray-300">{area.zone.name}</h4>
                                </div>
                                <div className="aspect-square border-2 border-gray-400 rounded-full flex items-center justify-center w-12 h-12 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                    <span className="text-gray-300 text-xl">    
                                        {area.number}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </section>
                ) : (
                    <div className="w-full">
                        <p className="text-gray-300 mb-3">Loading areas</p>
                        <ProgressBar mode="indeterminate" style={{height: '6px'}}></ProgressBar>
                    </div>
                )}
            </main>
        </>
    );
};

export default Areas;
