import React, {useRef} from 'react';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    className?: string;
}

const Textarea = ({className = '', ...props}: TextareaProps) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    return (
        <div>
            <textarea
                ref={textareaRef}
                className={`bg-gray-800 text-white border border-gray-600 rounded-[4px] py-2 px-4 focus:outline-none focus:border-gray-400 focus:bg-gray-700
                           transition-all duration-200 ease-in-out placeholder-gray-400 hover:border-gray-400 ${className}`}
                {...props}
            ></textarea>
        </div>
    );
};

export default Textarea;